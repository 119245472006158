<template>
  <div class="hypervisor-wall">
    <div style="margin-bottom: 16px">
      <create-button v-permission="{action: 'base.hypervisor.create', effect: 'disabled'}" @click="$refs.inputDrawer.show()"></create-button>
      <a-input-search
        class="hypervisor-search"
        style="float: right"
        placeholder="请输入虚拟化名称"
        @search="search"
      ></a-input-search>
    </div>
    <a-spin :spinning="spinning">
      <a-collapse :bordered="false" activeKey="hypervisor" style="cursor: default;">
        <a-collapse-panel key="hypervisor" :showArrow="false" :style="customStyle" disabled>
          <template #header>
            <div class="collapse-title">
              <span>虚拟化</span>
            </div>
          </template>
          <a-row v-if="hypervisorList.length" :gutter="16">
            <a-col
              v-for="hypervisor in hypervisorList"
              :key="hypervisor.id"
              :md="12"
              :lg="8"
              :xxl="6"
              style="margin-bottom: 16px"
            >
              <hypervisor-card
                :hypervisor="hypervisor"
                @edit="id => $refs.inputDrawer.show(id)"
                @delete="confirm"
              ></hypervisor-card>
            </a-col>
          </a-row>
          <div v-else style="height: 232px; position: relative; overflow: hidden;">
            <empty-component
              :imgUrl="require('@/assets/images/info_empty.png')"
              :body-style="{
                height: '200px',
                marginTop: '0px'
              }"
              :description-style="{
                marginTop: '-8px',
                marginRight: '8px'
              }"
              description="暂无虚拟化数据"
            ></empty-component>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </a-spin>

    <input-drawer ref="inputDrawer" @ok="fetch"></input-drawer>
  </div>
</template>

<script>
import { getHypervisorList, deleteHypervisor } from '@/api/hypervisor'
import HypervisorCard from './modules/HypervisorCard'
import CreateButton from '@/components/button/CreateButton.vue'

export default {
  name: 'HypervisorDashboardList',
  components: {
    HypervisorCard,
    CreateButton,
    InputDrawer: () => import('@/components/table/HypervisorTable/modules/InputDrawer'),
    EmptyComponent: () => import('@/components/EmptyComponent.vue')
  },
  data () {
    return {
      customStyle: {
        background: '#ffffff',
        borderRadius: '8px',
        marginBottom: '16px',
        border: 0,
        boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 8px'
      },
      params: {
        page: 1,
        pageSize: 10
      },
      total: 0,
      hypervisorList: [],
      spinning: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteHypervisor(id).then(res => {
          this.$message.success(res.message)
        })
      }
      this.fetch()
    },
    fetch () {
      this.spinning = true
      this.hypervisorList = []
      getHypervisorList(this.params).then(res => {
        this.hypervisorList.push(...res.data.data)
        this.total = res.data.total
        if (this.hypervisorList.length < this.total) this.fetch()
      }).finally(() => { this.spinning = false })
    },
    search (v) {
      if (v) {
        this.params = {
          page: 1,
          pageSize: 10,
          name: v
        }
        this.fetch()
      } else {
        this.params = {
          page: 1,
          pageSize: 10
        }
        this.fetch()
      }
    }
  }
}
</script>

<style lang="less">
.hypervisor-wall {
  .hypervisor-search {
    width: 180px;
    .ant-input {
      background-color: #fff;

  }
  }
  .ant-collapse-borderless {
    background: transparent;
    cursor: default;

    .ant-collapse-header {
      cursor: default;

      .collapse-title {
        padding-left: 24px;
        position: relative;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        height: 22px;
        line-height: 22px;
        cursor: default;

        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 20px;
          top: 1px;
          left: 10px;
          background: #096dd9;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
