var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",on:{"click":function($event){return _vm.$router.push({
      name: 'HypervisorDashboardDetail',
      params: {
        id: _vm.hypervisor.id
      }
    })}}},[_c('div',{staticClass:"header"},[_c('a',{staticClass:"title",on:{"click":function($event){return _vm.$router.push({
          name: 'HypervisorDashboardDetail',
          params: {
            id: _vm.hypervisor.id
          }
        })}}},[_vm._v(" "+_vm._s(_vm.hypervisor.name)+" ")]),_c('span',{staticClass:"more-btn",on:{"click":function($event){$event.stopPropagation();{}}}},[_c('a-dropdown',[_c('a-icon',{staticStyle:{"cursor":"pointer"},attrs:{"type":"ellipsis"}}),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',[_c('sync-button',{on:{"click":function($event){return _vm.sync(_vm.hypervisor.id)}}})],1),_c('a-menu-item',[_c('edit-button',{directives:[{name:"permission",rawName:"v-permission",value:({action: 'base.hypervisor.update', effect: 'disabled'}),expression:"{action: 'base.hypervisor.update', effect: 'disabled'}"}],on:{"click":function($event){return _vm.$emit('edit',_vm.hypervisor.id)}}})],1),_c('a-menu-item',[_c('delete-button',{directives:[{name:"permission",rawName:"v-permission",value:({action: 'base.hypervisor.delete', effect: 'disabled'}),expression:"{action: 'base.hypervisor.delete', effect: 'disabled'}"}],on:{"confirm":function($event){return _vm.$emit('delete', _vm.hypervisor.id)}}})],1)],1)],1)],1)]),_c('div',{staticClass:"body-container"},[_c('a-descriptions',{attrs:{"column":1}},[_c('a-descriptions-item',{attrs:{"label":"监控状态"}},_vm._l((_vm.monitorStatuses),function(item){return _c('monitor-status-tag',{key:item.name,attrs:{"status":item.value,"name":item.name}})}),1),_c('a-descriptions-item',{attrs:{"label":"IP 地址"}},[_vm._v(_vm._s(_vm.hypervisor.monitor_address))]),_c('a-descriptions-item',{attrs:{"label":"类型"}},[_vm._v(_vm._s(_vm.$t(`hypervisor_type.${_vm.hypervisor.hypervisor_type}`)))])],1)],1),_c('div',{staticClass:"footer"},[(_vm.statisticCount.length)?_vm._l((_vm.statisticCount),function(item){return _c('div',{key:item.name},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(`hypervisor.${item.name}`))+": ")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(item.value))])])}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }