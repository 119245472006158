<template>
  <div
    class="container"
    @click="
      $router.push({
        name: 'HypervisorDashboardDetail',
        params: {
          id: hypervisor.id
        }
      })
    "
  >
    <div class="header">
      <a
        class="title"
        @click="
          $router.push({
            name: 'HypervisorDashboardDetail',
            params: {
              id: hypervisor.id
            }
          })
        "
      >
        {{ hypervisor.name }}
      </a>
      <span class="more-btn" @click.stop="{}">
        <a-dropdown>
          <a-icon type="ellipsis" style="cursor: pointer"></a-icon>
          <a-menu slot="overlay">
            <a-menu-item>
              <sync-button @click="sync(hypervisor.id)"></sync-button>
            </a-menu-item>
            <a-menu-item>
              <edit-button v-permission="{action: 'base.hypervisor.update', effect: 'disabled'}" @click="$emit('edit',hypervisor.id)"></edit-button>
            </a-menu-item>
            <a-menu-item>
              <delete-button v-permission="{action: 'base.hypervisor.delete', effect: 'disabled'}" @confirm="$emit('delete', hypervisor.id)"></delete-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </div>

    <div class="body-container">
      <a-descriptions :column="1">
        <a-descriptions-item label="监控状态">
          <monitor-status-tag
            v-for="item in monitorStatuses"
            :key="item.name"
            :status="item.value"
            :name="item.name"
          ></monitor-status-tag>
        </a-descriptions-item>
        <a-descriptions-item label="IP 地址">{{ hypervisor.monitor_address }}</a-descriptions-item>
        <a-descriptions-item label="类型">{{ $t(`hypervisor_type.${hypervisor.hypervisor_type}`) }}</a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="footer">
      <template v-if="statisticCount.length">
        <div v-for="item in statisticCount" :key="item.name">
        <span class="title">{{$t(`hypervisor.${item.name}`)}}: </span><span class="value">{{item.value}}</span>
      </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getHypervisorMonitorStatusList, syncHypervisor, getHypervisorCount } from '@/api/hypervisor'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import SyncButton from '@/components/button/SyncButton'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'

export default {
  name: 'HypervisorCard',
  props: {
    hypervisor: {
      type: Object,
      required: true
    }
  },
  components: {
    DeleteButton,
    EditButton,
    MonitorStatusTag,
    SyncButton
  },
  data () {
    return {
      monitorStatuses: [],
      statisticCount: []
    }
  },
  mounted () {
    this.fetch('status')
  },
  methods: {
    fetch (type) {
      switch (type) {
        case 'status':
          getHypervisorMonitorStatusList(this.hypervisor.id).then(res => {
            this.monitorStatuses = res.data.data
          })
          getHypervisorCount(this.hypervisor.id).then(res => {
            this.statisticCount = res.data.data
          })
          break
      }
    },
    sync (id) {
      syncHypervisor({ id }).then(res => {
        this.$message.success(res.message)
      })
    }
  }
}
</script>

<style scoped>
.container {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 13px;
  cursor: pointer;
}
.header {
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 48px;
  box-sizing: border-box;
}
.header .title {
  padding-left: 8px;
}
.more-btn {
  float: right;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.25);
}
.body-container {
  padding: 19px 8px 0 18px;
}
.body-container >>> .ant-descriptions-item-label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.body-container >>> .ant-descriptions-item-content {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.footer {
  display: flex;
  padding: 6px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: space-around;
  height: 35px;
}
.footer>div {
  margin-right: 4px;
}
.footer .title {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.footer .value {
  font-size: 14px;
  color: #fd9c00;
  font-weight: 500;
}
</style>
